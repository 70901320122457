<template>
    <div class="step-setup">
        <div class="container">
            <template v-if="!introCompleted">
                <div class="intro-wrapper">
                    <div class="profile-wrapper fadeInUp" v-if="profileImageUrl">
                        <ImageThumbnail class="image-thumbnail" :src="profileImageUrl" :type="0" :h="500" :w="500" />
                    </div>
                    <h5 class="font-weight-bold text-center fadeInUp delay-1">{{ fullName }}'s Tribute Video</h5>
                    <div class="card mw-500 fadeInUp delay-2">
                        <div class="card-body fh-card">
                            <div v-if="tributeVideo.funeralHomeLogo" class="fh-branding">
                                <img :src="tributeVideo.funeralHomeLogo" class="fh-logo" />
                            </div>
                            <h6 v-if="funeralHomeName" class="fh-name font-weight-bold">
                                {{ funeralHomeName }}
                            </h6>
                            <div class="fh-intro-text">
                                <p class="mb-0" v-if="state.isContributor">
                                    Invites you to add your photo memories to create a memorable tribute video.
                                </p>
                                <p class="mb-0" v-else>
                                    Invites you to add photo & video memories, set a theme, and add music to create a
                                    memorable tribute video.
                                </p>
                            </div>
                            <!-- <div class="fh-cta">
                                <v-btn outlined color="orange" class="mt-1">
                                    <font-awesome-icon
                                        class="mr-1"
                                        icon="fa-regular fa-play-circle"
                                    ></font-awesome-icon>
                                    Watch a quick how-to video
                                </v-btn>
                            </div> -->
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <v-stepper v-model="introStep" class="transparent elevation-0">
                    <v-stepper-header class="white custom-header">
                        <v-stepper-step
                            :complete="introStep > 1"
                            step="1"
                            :edit-icon="null"
                            :complete-icon="null"
                            @click="introStep = 1"
                        >
                            <font-awesome-icon class="mr-1" icon="fa-solid fa-images"></font-awesome-icon>
                            Your Photos
                        </v-stepper-step>

                        <div class="step-divider">
                            <font-awesome-icon class="mr-1" icon="fa-solid fa-chevron-right"></font-awesome-icon>
                        </div>

                        <v-stepper-step :complete="introStep > 2" step="2" @click="introStep = 2">
                            <font-awesome-icon class="mr-1" icon="fa-solid fa-sliders-h"></font-awesome-icon>
                            Customization
                        </v-stepper-step>

                        <div class="step-divider">
                            <font-awesome-icon class="mr-1" icon="fa-solid fa-chevron-right"></font-awesome-icon>
                        </div>

                        <v-stepper-step step="3" @click="introStep = 3">
                            <font-awesome-icon class="mr-1" icon="fa-solid fa-user"></font-awesome-icon>
                            Your Details
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card shaped class="text-center elevation-0 intro-card">
                                <h4 class="intro-title">
                                    <font-awesome-icon icon="fa-regular fa-images"></font-awesome-icon> What type of
                                    photos will you use?
                                </h4>
                                <div class="media-types">
                                    <!-- Physical Photos Option -->
                                    <div
                                        @click="
                                            userHasPhysicalPhotos = true;
                                            introStep++;
                                        "
                                        class="media-card border-2 rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all cursor-pointer group"
                                        :class="[userHasPhysicalPhotos === true ? 'selected' : '']"
                                    >
                                        <div class="media-card-image p-4 flex justify-center">
                                            <img
                                                src="https://img.icons8.com/fluency/96/photo-album.png"
                                                alt="Physical photos"
                                                class="h-32 w-auto"
                                            />
                                        </div>
                                        <div class="media-card-content pa-2 text-center">
                                            <h6 class="font-weight-medium mb-2">Physical Photos</h6>
                                            <p class="">I have printed photographs that need to be digitized</p>
                                            <div
                                                class="mt-3 media-card-selected-text"
                                                :class="{ active: userHasPhysicalPhotos === true }"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-check-circle"></font-awesome-icon>
                                                Selected
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Digital Photos Option -->
                                    <div
                                        @click="
                                            userHasPhysicalPhotos = false;
                                            introStep++;
                                        "
                                        class="media-card border-2 rounded-xl overflow-hidden shadow-sm"
                                        :class="[userHasPhysicalPhotos === false ? 'selected' : '']"
                                    >
                                        <div class="media-card-image p-4 flex justify-center">
                                            <img
                                                src="https://img.icons8.com/fluency/96/cloud-folder.png"
                                                alt="Digital photos"
                                                class="h-32 w-auto"
                                            />
                                        </div>
                                        <div class="media-card-content pa-2 text-center">
                                            <h6 class="font-weight-medium mb-2">Digital Photos</h6>
                                            <p class="text-gray-600">My photos are already in digital format</p>
                                            <div
                                                class="mt-3 media-card-selected-text"
                                                :class="{ active: userHasPhysicalPhotos === false }"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-check-circle"></font-awesome-icon>
                                                Selected
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card shaped class="text-center elevation-0 intro-card">
                                <h4 class="intro-title">
                                    <font-awesome-icon icon="fa-solid fa-sliders-h"></font-awesome-icon> How much
                                    customization do you want?
                                </h4>

                                <!-- Low Customization -->
                                <div
                                    @click="
                                        userWantsCustomization = false;
                                        introStep++;
                                    "
                                    class="customization-block"
                                    :class="{ selected: userWantsCustomization === false }"
                                >
                                    <div class="customization-icon">
                                        <!-- faMagicWandSparkles -->
                                        <font-awesome-icon icon="fa-solid fa-magic-wand-sparkles"></font-awesome-icon>
                                    </div>
                                    <div class="customization-details">
                                        <h5 class="text-lg font-medium text-orange-900">Simple Creation</h5>
                                        <p class="text-gray-600 text-sm">
                                            Upload photos and let us do the rest with minimal editing required
                                        </p>
                                    </div>
                                    <div class="selected-icon">
                                        <font-awesome-icon
                                            icon="fa-solid fa-check-circle"
                                            class="fa-xl"
                                            v-show="userWantsCustomization === false"
                                        />
                                    </div>
                                </div>

                                <div
                                    @click="
                                        userWantsCustomization = true;
                                        introStep++;
                                    "
                                    class="customization-block"
                                    :class="{ selected: userWantsCustomization === true }"
                                >
                                    <div class="customization-icon">
                                        <font-awesome-icon icon="fa-solid fa-sliders-h"></font-awesome-icon>
                                    </div>
                                    <div class="customization-details">
                                        <h5 class="text-lg font-medium text-orange-900">Advanced Customization</h5>
                                        <p class="text-gray-600 text-sm">
                                            Edit photos, apply filters, control layout, timing, and transitions
                                        </p>
                                    </div>
                                    <div class="selected-icon">
                                        <font-awesome-icon
                                            icon="fa-solid fa-check-circle"
                                            class="fa-xl"
                                            v-show="userWantsCustomization === true"
                                        />
                                    </div>
                                </div>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-card class="text-center intro-card">
                                <template v-if="!contributorDetailsSet">
                                    <div class="field">
                                        <div class="mb-3">
                                            <h4 class="intro-title">
                                                <font-awesome-icon icon="fa-solid fa-user"></font-awesome-icon>
                                                Tell us about yourself
                                            </h4>
                                            <!-- <p class="mb-0">To continue please state your name and relationship to {{ firstName }}.</p> -->
                                        </div>
                                        <div class="mb-3 field">
                                            <h6 class="font-weight-bold">Name</h6>
                                            <v-text-field v-model="contributor.name" outlined dense></v-text-field>
                                        </div>
                                        <div class="mb-3 field">
                                            <h6 class="font-weight-bold">Relationship</h6>
                                            <v-select
                                                outlined
                                                dense
                                                v-model="contributor.relationship"
                                                :items="['Acquaintance', 'Relative', 'Friend', 'Family']"
                                            ></v-select>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="font-weight-medium mt-4 text-center">
                                        <h4 class="intro-title">
                                            <font-awesome-icon icon="fa-solid fa-user"></font-awesome-icon>
                                            Welcome back
                                        </h4>
                                        <p>
                                            {{ contributorFirstName }} ({{ contributor.relationship }})
                                            <v-btn class="ml-2" outlined small @click="resetContributeDetailsSet">
                                                <font-awesome-icon icon="fa-regular fa-pencil"></font-awesome-icon>
                                            </v-btn>
                                        </p>
                                    </div>
                                </template>

                                <template v-if="userHasPhysicalPhotos || userWantsCustomization">
                                    <h5 class="intro-title">Our Recommendation</h5>
                                    <div class="recommendation-block">
                                        <h5 class="recommendation-title">Download Our Mobile App</h5>
                                        <p>
                                            Based on your preferences, our mobile app is the perfect choice. It provides
                                            tools for advanced customization options.
                                        </p>
                                        <a
                                            href="https://apps.apple.com/app/id1665247224"
                                            target="_blank"
                                            class="appstore-button"
                                        >
                                            <div class="appstore-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 384 512"
                                                    width="20"
                                                >
                                                    <path
                                                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                                                    />
                                                </svg>
                                            </div>
                                            <div>
                                                <div class="text-xs">Download on the</div>
                                                <div class="appstore-label">App Store</div>
                                            </div>
                                        </a>
                                    </div>
                                </template>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </template>
            <template v-if="tributeVideoExists">
                <div class="container-footer" v-if="!introCompleted || introStep === 3">
                    <button
                        :disabled="loading || conditionallyRequired"
                        @click="handleClickNext"
                        class="primary-button fadeInUp"
                        :class="{ 'delay-3': !contributorDetailsSet }"
                    >
                        <span class="button-text">Next</span>
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import Calendar from '@/components/ui/Calendar.vue';
import SlideUpload from '@/components/Tribute/SlideUpload.vue';
import { mapActions, mapGetters } from 'vuex';
import ImageThumbnail from '../Media/ImageThumbnail.vue';
import ImagePreview from '../Media/ImagePreview.vue';
import moment from 'moment';
import { isEmpty } from 'lodash';
export default {
    name: 'TributeSetup',
    data() {
        return {
            deadline: {
                date: null,
                time: null,
            },
            defaultDeadline: null,
            image: {
                previewURL: null,
                file: null,
            },
            introStep: 1,
            userHasPhysicalPhotos: null,
            userWantsCustomization: null,
            loading: false,
            token: null,
            apiService: null,
            pendingUpload: null,
            tempFiles: [],
            contributor: {
                name: null,
                relationship: null,
            },
            //
            introCompleted: false,
            funeralHomeName: null,
        };
    },
    inject: ['state'],
    props: {
        serviceSlug: {
            type: String,
            required: true,
        },
        tributeVideo: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        calendar: Calendar,
        SlideUpload,
        ImageThumbnail,
        ImagePreview,
    },
    computed: {
        ...mapGetters('tributeVideo', ['deadlineExpired']),
        tributeVideoExists() {
            if (!this.tributeVideo || !this.tributeVideo.id) return false;

            return this.tributeVideo.id > 0;
        },
        fullName() {
            if (this.tributeVideo?.firstName && this.tributeVideo?.lastName) {
                return `${this.tributeVideo?.firstName} ${this.tributeVideo?.lastName}`;
            }
            return this.firstName;
        },
        firstName() {
            return this.tributeVideo?.firstName || 'Honoree';
        },
        conditionallyRequired() {
            // return true to disable fields
            // disable next until fields are set
            if (!this.introCompleted) {
                return false;
            }
            if (this.introStep <= 3) {
                if (this.introStep === 1) {
                    // lock until user has chosen photo types
                    return this.userHasPhysicalPhotos === null;
                }
                if (this.introStep === 2) {
                    // lock until user picks customization type
                    return this.userWantsCustomization === null;
                }
                if (this.introStep === 3) {
                    // lock until user sets name
                    if (!this.contributor.name || !this.contributor.name.length) {
                        return true;
                    }
                    if (!this.contributor.relationship || !this.contributor.relationship.length) {
                        return true;
                    }
                }
            }
            return false;
        },
        contributorFirstName() {
            if (this.contributor?.name?.length > 0) {
                const words = this.contributor.name.split(' ');
                return words[0];
            }
            return '';
        },
        disableProfileUpdate() {
            return this.state.isContributor || (this.state.isFamily && !this.contributorDetailsSet);
        },
        contributorDetailsSet() {
            return this.$store.state.tributeVideo.contributorDetailsSet;
        },
        profileUploadProgress() {
            return this.$store.state.tributeVideo.profileUploadProgress;
        },
        showProfileUploadProgress() {
            return this.$store.state.tributeVideo.showProfileUploadProgress;
        },
        pendingProfileUpload() {
            return this.$store.state.tributeVideo.pendingProfileUpload;
        },
        profileImageUrl() {
            return this.$store.state.tributeVideo.mainPhotoUrl;
        },
    },

    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeVideo', [
            'updateProfileUploadProgress',
            'updateShowProfileUploadProgress',
            'updatePendingProfileUpload',
        ]),
        resetContributeDetailsSet() {
            this.$store.dispatch('tributeVideo/updateTributeVideo', {
                ...this.tributeVideo,
                contributorDetailsSet: false,
            });
        },
        datesAreEqual(date1, date2) {
            const dateString1 = this.$moment.utc(date1).toISOString();
            const dateString2 = this.$moment.utc(date2).toISOString();
            return dateString1 === dateString2;
        },
        async handleClickNext() {
            const dateHasChanged = !this.datesAreEqual(this.tributeVideo?.deadline, this.deadline?.date);

            if (dateHasChanged) {
                await this.updateDeadline();
            }

            if (this.state.isContributor || this.state.isFamily) {
                if (!this.introCompleted) {
                    this.introCompleted = true;
                    // Todo remove this return here;
                    return;
                }
                if (this.introStep < 3) {
                    this.introStep++;
                    return;
                }
                // If the fields aren't filled out then prevent going to next state
                if (isEmpty(this.contributor.name) || isEmpty(this.contributor.relationship)) {
                    this.showSnackbar({ message: 'Please fill out the fields.', color: 'error' });
                    return;
                }
                this.$emit('setAnonUserDetails', this.contributor);
            }
            this.$emit('next-step');
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        parseDeadline(deadline) {
            if (!deadline) return;

            const localDeadline = this.$moment.utc(deadline).local();
            return localDeadline.toISOString();
        },
    },
    async mounted() {
        if (this.state.token) {
            this.token = this.state.token;
        } else {
            await this.setAuthToken();
        }

        this.apiService = initApiServices(this.token);
        if (this.tributeVideo?.deadline) {
            this.deadline.date = this.parseDeadline(this.tributeVideo?.deadline);
        }
        console.log(this.tributeVideo, 'tribute video on setup mount');

        if (this.state.isFamily || this.state.isContributor) {
            const tributeUserDetails = localStorage.getItem('tributeContributor');
            if (typeof tributeUserDetails === 'string') {
                this.contributor = JSON.parse(tributeUserDetails);
                // If user already has details set then we can assume they've completed the intro too
                this.introCompleted = true;
                this.introStep = 3;
                // this.contributorDetailsSet = true;
                this.$store.dispatch('tributeVideo/updateTributeVideo', {
                    ...this.tributeVideo,
                    contributorDetailsSet: true,
                });
            }
        }
    },
    watch: {
        '$props.tributeVideo': {
            handler(newVal) {
                if (newVal.deadline) {
                    this.deadline.date = this.parseDeadline(newVal.deadline);
                }

                if (!this.funeralHomeName && newVal.funeralHomeName) {
                    this.funeralHomeName = newVal.funeralHomeName;
                }
            },
            deep: true,
            immediate: true,
        },
        state: {
            handler(newVal, oldVal) {
                if (newVal?.anonUserDetails) {
                    if (newVal.anonUserDetails?.name) {
                        this.contributor.name = newVal.anonUserDetails.name;
                    }
                    if (newVal.anonUserDetails?.relationship) {
                        this.contributor.relationship = newVal.anonUserDetails.relationship;
                    }
                    // this.contributorDetailsSet = true;
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        ...this.tributeVideo,
                        contributorDetailsSet: true,
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.step-setup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    max-height: 100%;
}

.container {
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-items: center;
    max-height: 100%;
    height: calc(100dvh - 70px);
    justify-content: space-between;
    .primary-button {
        max-width: 300px;
    }
    @include mobile() {
        width: auto;
    }
    .container-footer {
        width: 100%;
        display: flex;
        justify-content: center;

        @include mobile() {
            padding-top: 6px;
            // backdrop-filter: blur(10px);
            background: linear-gradient(0deg, #f3f4f6, transparent);
            position: sticky;
            bottom: 0;
        }
    }
}

.select-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
}

.select-button {
    padding: 10px;
    width: 100%;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    border-radius: 6px;
}

.secondary-button {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;
    flex-grow: 1;
    justify-content: center;
    &:hover {
        background-color: $light-gray;
    }
}

.danger-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;

    &:hover {
        background: #ffd3d3;
    }
}

#image {
    display: none;
}

.upload-progress-ring {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
}
.primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    background-color: $primary-orange;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 6px;
    transition: all 0.3s;

    &:disabled {
        background-color: lighten($primary-orange, 20%);
        cursor: not-allowed;
    }
}

.pending-slide {
    height: 100%;
    width: 100%;

    img {
        // opacity: 0.4;
        height: 100%;
        width: 100%;
        max-width: 300px;
        object-fit: cover;
    }

    .progress-indicator {
        width: 93%;
        display: inline-block;
        height: 5px;
        bottom: 10px;
        position: absolute;
        left: 0;
        right: 0;
        background: #ffefdb;
        margin: auto;
        border-radius: 10rem;
    }

    .progress-slider {
        height: 100%;
        background: #f97316;
        border-radius: 10rem;
        transition: width 200ms ease-in-out;
    }
}

.profile-wrapper {
    aspect-ratio: 1;
    border-radius: 10rem;
    max-width: 300px;
    overflow: hidden;
    position: relative;
    &.disabled {
        opacity: 1;
        cursor: auto;
    }
    @include mobile() {
        max-width: min(45vw, 201px);
    }
}

.profile-wrapper:not(.disabled):hover {
    opacity: 0.8;
}

.image-preview {
    // position: relative;
    display: inline-block;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    aspect-ratio: 1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.image-preview img {
    border-radius: 8px;
}

.profile-place-holder {
    background-color: #dbe2e8;
    height: 100%;
    width: 100%;
    flex-grow: 1;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    h1 {
        // color: white;
        margin: 0;
        padding: 0;
    }
}
// .field {
//     width: 100%;
// }
.fh-card {
    .fh-branding {
        float: left;
        margin-right: 14px;
        max-width: 100px;
        border-radius: 10px;
        overflow: hidden;
    }
    .fh-cta {
        display: flex;
        justify-content: center;
        // margin: auto;
        margin-top: 10px;
    }
}
@include mobile() {
    .fh-card {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;

        .fh-branding {
            flex: 1 1 20%;
            border-radius: 10px;
            overflow: hidden;
            max-width: 70px;
        }
        .fh-name {
            flex: 1;
        }
    }
}

.media-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    transition: all 25ms ease-in-out;
    @include mobile() {
        display: flex;
        flex-direction: column;
    }
    .media-card-image {
        @include mobile() {
            height: 80px;
            padding: 0 !important;
        }
    }
}
.intro-title {
    color: $burnt-orange;
    @include mobile() {
        font-size: 1.1rem;
        margin: 0;
    }
}
.step-divider {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: lighten($btn-orange, 10%);
    padding: 0 10px;
}
.intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.intro-card {
    padding: 2rem;
    border-radius: 24px !important;
    box-shadow: none !important;
    gap: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
    @include mobile() {
        padding: 1rem;
    }
}
.media-card {
    border: 2px solid $light-gray;
    cursor: pointer;
    &.selected {
        border-color: $btn-orange;
    }
    .media-card-image {
        background: lighten($btn-orange, 38%);
        margin-bottom: 10px;
    }
    .media-card-content {
        padding: 6px;
        h6 {
            color: $burnt-orange;
        }
    }
    .media-card-selected-text {
        color: $primary-orange;
        visibility: hidden;
        &.active {
            visibility: visible;
        }
    }
}

@include mobile() {
    ::v-deep {
        .v-stepper {
            overflow: visible;
        }
        .v-stepper__content {
            padding: 1rem 0 0 !important;
        }
    }
}
.custom-header {
    border-radius: 40px;
    width: 100%;
    box-shadow: none;
    align-content: center;
    height: 86%;
    margin: auto 0;
    height: 60px;
    padding: 23px 8px;

    &::v-deep {
        .v-stepper {
            flex: 1;
        }
        .v-stepper__wrapper {
            @include mobile() {
                overflow: auto !important;
            }
        }
        .v-stepper__step__step {
            display: none;
        }
        .v-stepper__step {
            height: 100%;
            margin: auto 0;

            .v-stepper__label {
                display: inline-block !important;
                font-weight: bold;
                font-size: 0.8rem;
                align-content: center;
            }
            &.v-stepper__step--active {
                .v-stepper__label {
                    color: $primary-orange;
                    align-content: center;
                    font-size: 0.8rem;
                }
            }
            &.v-stepper__step--complete {
                background: $primary-orange;
                border-radius: 40px;
                .v-stepper__label {
                    color: white !important;

                    padding: 0 11px;
                }
            }
        }
    }
    @include mobile() {
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 7px;
        box-shadow: 0 6px 7px rgba(0, 0, 0, 0.1);
        &::v-deep {
            gap: 0;
            flex-wrap: nowrap;

            .v-stepper__step {
                flex-direction: column;
                justify-content: center;
                padding: 0;

                .v-stepper__label {
                    text-align: center;
                    line-height: 1.2;
                }
            }
        }
    }
}
.customization-block {
    // border rounded-xl p-6 mb-6 cursor-pointer transition-all hover:bg-orange-100
    border: 1px solid darken($light-gray, 4%);
    border-radius: 10px !important;
    background: #fefefe;
    &:hover {
        background-color: lighten($btn-orange, 40%);
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 10px;
    min-height: 100px;
    cursor: pointer;

    .customization-icon {
        // w-16 h-16 bg-orange-200 text-orange-700 rounded-lg flex items-center justify-center
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: lighten($btn-orange, 30%);
        width: 60px;
        align-self: stretch;
        margin: 10px;
        border-radius: 9px;
        color: $burnt-orange;
        font-size: 1.5rem;
        transition: all 250ms ease-in-out;
    }
    .customization-details {
        flex: 1;
        justify-items: flex-start;
        text-align: left;
        & > * {
            margin: 0;
        }
        h5 {
            color: $burnt-orange;
            font-weight: bold;
        }
    }
    .selected-icon {
        color: $burnt-orange;
        width: 60px;
    }
    &.selected {
        border-color: $btn-orange;
        background-color: lighten($btn-orange, 40%);
    }
    @include mobile() {
        flex-direction: column;

        .customization-icon {
            aspect-ratio: 1;
            height: 50px;
            margin: 0;
            width: 50px;
        }
        .selected-icon {
            position: absolute;
            right: 0;
            margin: 0 9px;
        }
    }
}
.recommendation-block {
    background: #ffeed4;
    border: 2px solid darken(#ffeed4, 10%);
    border-radius: 10px !important;
    padding: 2.5rem 1rem;
    @include mobile() {
        padding: 1.5rem 1rem;
    }
    .recommendation-title {
        color: $burnt-orange;
        font-weight: bold;
    }
    .appstore-button {
        background: $btn-orange;
        color: white;
        display: flex;
        flex-direction: row;
        width: 200px;
        border-radius: 10px;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin: auto;
        height: 65px;
        cursor: pointer;
        line-height: 1.1;
        .text-xs {
            font-size: 0.8rem;
            font-weight: bold;
            color: white;
        }
        .appstore-icon {
            color: white;
            fill: white;
        }
        .appstore-label {
            font-size: 1.2rem;
            font-weight: bold;
            text-decoration: none;
            color: white;
        }
        &:hover {
            background: lighten($btn-orange, 12%);
            text-decoration: none;
        }
    }
}
.mw-500 {
    max-width: 500px;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    animation-duration: 400ms;
    animation-fill-mode: both;
    animation-name: fadeInUp;
    animation-delay: 100ms;
}

.delay-1 {
    animation-delay: 400ms;
}
.delay-2 {
    animation-delay: 1200ms;
}
.delay-3 {
    animation-delay: 1800ms;
}
</style>
