<template>
    <div class="p-1">
        <v-alert
            outlined
            text
            type="warning"
            class="text-center"
            v-if="settingsForm.streamingProvider == 'RTSP' || settingsForm.streamingProvider == 'WebRTC'"
            >Warning! The MemoryShare app will NOT work with {{ settingsForm.streamingProvider }} set as the video
            source.</v-alert
        >
        <div v-if="$auth.role.includes('SuperAdmin')">
            <v-form v-model="formValid" ref="form">
                <h5 class="mt-3 ml-1">Account Toggles</h5>
                <div class="section mt-0">
                    <!-- <div style="display: flex; flex-wrap: wrap"> -->
                    <!-- <v-checkbox
                            v-model="settingsForm.displayTutorial"
                            label="Display Tutorial"
                            style="width: 180px"
                        ></v-checkbox> -->
                    <!-- <v-checkbox
                            v-model="settingsForm.enablePreview"
                            label="Enable Preview"
                            style="width: 180px"
                        ></v-checkbox> -->

                    <!-- <v-checkbox
                            v-if="$auth.role.includes('SuperAdmin')"
                            v-model="settingsForm.dvdForSale"
                            label="DVD For Sale"
                            style="width: 180px"
                        ></v-checkbox> -->
                    <v-row>
                        <v-col v-if="$auth.role.includes('SuperAdmin')">
                            <v-checkbox
                                v-model="settingsForm.transcoded"
                                label="Transcoded"
                                style="width: 180px"
                            ></v-checkbox>
                        </v-col>
                        <v-col v-if="$auth.role.includes('SuperAdmin')">
                            <v-checkbox
                                v-model="settingsForm.premiumSupport"
                                label="Premium Support"
                                style="width: 180px"
                            ></v-checkbox>
                        </v-col>
                        <v-col v-if="$auth.role.includes('SuperAdmin')">
                            <v-checkbox
                                v-model="settingsForm.shopLinkDisplayed"
                                label="Ecomm"
                                style="width: 180px"
                            ></v-checkbox>
                        </v-col>
                        <v-col v-if="$auth.role.includes('SuperAdmin') && settingsForm.tributeVideos">
                            <v-checkbox
                                v-model="settingsForm.tributeVideoHDRender"
                                label="HD Tribute Render"
                                style="width: 180px"
                            ></v-checkbox>
                        </v-col>
                        <v-col v-if="$auth.role.includes('SuperAdmin')">
                            <v-checkbox v-model="settingsForm.hlsEnabled" label="HLS" style="width: 180px"></v-checkbox>
                        </v-col>
                        <!-- <v-col>
                            <v-checkbox
                                v-if="$auth.role.includes('SuperAdmin')"
                                v-model="settingsForm.tributeVideos"
                                label="Tribute Videos"
                                style="width: 180px"
                                disabled
                            ></v-checkbox>
                        </v-col> -->
                    </v-row>

                    <!-- </div> -->
                </div>

                <div class="section pt-6">
                    <v-row v-if="$auth.role.includes('SuperAdmin')">
                        <!-- <v-col cols="12" md="6">
                            <v-select
                                v-model="settingsForm.azureVMSize"
                                :items="azureVMSizes"
                                label="Azure VM Size"
                            ></v-select>
                        </v-col> -->
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="settingsForm.wowzaServerLocation"
                                :items="wowzaServerLocations"
                                label="Wowza Server Location"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <!-- <v-select v-model="settingsForm.websiteProvider" :items="websiteProviders" label="Website Provider"></v-select> -->
                            <v-select
                                v-model="settingsForm.embedCodeId"
                                :items="embedCodeData"
                                item-text="description"
                                item-value="id"
                                label="Embed Code"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="settingsForm.streamingProvider"
                                :items="streamingProviders"
                                label="Video Source"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="settingsForm.cloudStreamingProvider"
                                :items="cloudStreamingProviders"
                                item-text="label"
                                item-value="value"
                                label="Cloud Streaming Provider"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="settingsForm.storageType"
                                item-text="label"
                                item-value="value"
                                :items="filteredStorageTypes"
                                label="Storage Type"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="settingsForm.marketRadiusMiles"
                                type="number"
                                label="Market Radius (Miles)"
                                min="0"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <!-- <v-text-field
                                v-if="settingsForm.tributeVideos"
                                type="number"
                                min="1"
                                :max="maxTributeLimit"
                                :rules="[notGreaterThanMaxLimit]"
                                v-model="settingsForm.maxTributeVideoItems"
                                label="Max Tribute Video Items"
                            ></v-text-field> -->

                            <span class="text-caption">Max Tribute Video Items</span>
                            <div style="gap: 12px" class="d-flex align-center">
                                <v-slider
                                    v-if="settingsForm.tributeVideos"
                                    v-model="settingsForm.maxTributeVideoItems"
                                    min="1"
                                    max="500"
                                    :rules="[
                                        v => v >= 10 || 'Cannot be less than 10',
                                        v => v <= 500 || 'Cannot be greater than 500',
                                    ]"
                                >
                                </v-slider>
                                <v-text-field
                                    v-model="settingsForm.maxTributeVideoItems"
                                    type="number"
                                    style="max-width: 80px"
                                    dense
                                    outlined
                                    :rules="[
                                        v => v >= 10 || 'Cannot be less than 10',
                                        v => v <= 500 || 'Cannot be greater than 500',
                                    ]"
                                ></v-text-field>
                            </div>
                            <v-slide-y-transition>
                                <div class="text-caption" v-show="settingsForm.maxTributeVideoItems > 300">
                                    Warning: Exceeding 300 tribute video items may cause exceptionally long render
                                    times.
                                </div>
                            </v-slide-y-transition>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select
                                v-model="settingsForm.betaFeatures"
                                item-text="label"
                                item-value="value"
                                :items="betaFeaturesOptions"
                                label="Beta Features"
                            ></v-select>
                        </v-col>
                    </v-row>
                </div>

                <div class="section pt-6">
                    <h5>GuestBook Config</h5>
                    <v-text-field v-model="settingsForm.guestBookConfigURL"></v-text-field>
                </div>

                <!-- <div class="section elevation-2"> -->
                <!-- <v-checkbox v-model="settingsForm.displayCTA" class="clear-bottom" style="width: 220px">
                        <template v-slot:label>
                            <div>
                                Display Call To Action
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <font-awesome-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            icon="fa-regular fa-circle-question"
                                            class="custom-class"
                                        />
                                    </template>
                                    <span>Displayed on the bottom right of the video player</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-checkbox> -->
                <!-- <div :class="[{ 'disabled-bg': !settingsForm.displayCTA }, 'ease']">
                        <v-text-field
                            v-model="settingsForm.callToActionHeading"
                            label="Call To Action Heading"
                        ></v-text-field>
                        <v-text-field
                            v-model="settingsForm.callToActionButtonText"
                            label="Call To Action Button Text"
                        ></v-text-field>
                        <v-text-field
                            v-model="settingsForm.callToActionButtonLink"
                            label="Call To Action Button Link"
                        ></v-text-field>
                    </div> -->
                <!-- </div> -->

                <div class="mt-4 p- text-right">
                    <!-- <v-btn v-if="!busy" @click="dialog = false" class="mr-2">cancel</v-btn> -->
                    <v-btn @click="save()" :loading="busy" :disabled="busy || !formValid" color="primary">Save</v-btn>
                </div>
            </v-form>
        </div>

        <v-form v-else v-model="formValid" ref="form">
            <!-- <logo-upload :funeralHomeId="$auth.funeralHomeId" @refresh="handleRefresh()"></logo-upload> -->
            <!-- <v-checkbox v-model="settingsForm.displayTutorial" label="Display Call To Action"></v-checkbox>
            <v-text-field v-model="settingsForm.callToActionHeading" label="Call To Action Heading"></v-text-field>
            <v-text-field
                v-model="settingsForm.callToActionButtonText"
                label="Call To Action Button Text"
            ></v-text-field>
            <v-text-field
                v-model="settingsForm.callToActionButtonLink"
                label="Call To Action Button Link"
            ></v-text-field> -->

            <v-row>
                <v-col cols="12" md="6">
                    <!-- <v-select v-model="settingsForm.websiteProvider" :items="websiteProviders" label="Website Provider"></v-select> -->
                    <v-select
                        v-model="settingsForm.embedCodeId"
                        :items="embedCodeData"
                        item-text="description"
                        item-value="id"
                        label="Embed Code"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                        v-model="settingsForm.streamingProvider"
                        :items="streamingProviders"
                        label="Video Source"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="settingsForm.marketRadiusMiles"
                        type="number"
                        label="Market Radius (Miles)"
                        min="0"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="text-caption">Max Tribute Video Items</span>
                    <div style="gap: 12px" class="d-flex align-center">
                        <v-slider
                            v-if="settingsForm.tributeVideos"
                            v-model="settingsForm.maxTributeVideoItems"
                            min="1"
                            max="500"
                            :rules="[
                                v => v >= 10 || 'Cannot be less than 10',
                                v => v <= 500 || 'Cannot be greater than 500',
                            ]"
                        >
                        </v-slider>
                        <v-text-field
                            v-model="settingsForm.maxTributeVideoItems"
                            type="number"
                            style="max-width: 80px"
                            dense
                            outlined
                            :rules="[
                                v => v >= 10 || 'Cannot be less than 10',
                                v => v <= 500 || 'Cannot be greater than 500',
                            ]"
                        ></v-text-field>
                    </div>
                    <v-slide-y-transition>
                        <div class="text-caption" v-show="settingsForm.maxTributeVideoItems > 300">
                            Warning: Exceeding 300 tribute video items may cause exceptionally long render times.
                        </div>
                    </v-slide-y-transition>
                </v-col>
            </v-row>

            <!-- <v-checkbox v-model="settingsForm.displayTutorial" label="Display Tutorial"></v-checkbox> -->
            <!-- <v-checkbox
                v-if="$auth.role.includes('SuperAdmin')"
                v-model="settingsForm.dvdForSale"
                label="DVD For Sale"
            ></v-checkbox> -->
            <v-checkbox
                v-if="$auth.role.includes('SuperAdmin')"
                v-model="settingsForm.transcoded"
                label="Transcoded"
            ></v-checkbox>

            <!-- <v-checkbox
                v-if="!$auth.role.includes('SuperAdmin')"
                v-model="settingsForm.enablePreview"
                label="Enable Preview"
            ></v-checkbox> -->

            <div class="mt-4 text-right">
                <v-btn @click="save()" :loading="busy" :disabled="busy" color="primary">Save</v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import LogoUpload from '@/components/Misc/LogoUpload';
import { storageTypes, betaFeaturesOptions } from '@/constants';
import FuneralHomeService from '@/services/funeralHome.service';

export default {
    name: 'GeneralSettings',
    props: ['modal', 'token', 'admin', 'id'],
    components: {
        LogoUpload,
    },
    data() {
        return {
            storageTypes,
            betaFeaturesOptions,
            embedCodeData: [],
            dialog: false,
            home: '',
            busy: false,
            maxTributeLimit: 500,
            formValid: false,
            funeralHomeApi: null,
            settingsForm: {
                // azureVMSize: 'Standard_D4_v2',
                wowzaServerLocation: 'us_central_iowa',
                websiteProvider: '',
                streamingProvider: '',
                cloudStreamingProvider: 0,
                // displayTutorial: true,
                // dvdForSale: true,
                // displayCTA: false,
                transcoded: true,
                whiteLabel: false,
                premiumSupport: false,
                shopLinkDisplayed: false,
                tributeVideos: false,
                callToActionHeading: '',
                callToActionButtonText: '',
                callToActionButtonLink: '',
                // enablePreview: false,
                embedCodeId: '',
                tributeVideoHDRender: false,
            },
            azureVMSizes: ['Standard_D4_v2', 'Standard_D1_v2'],
            cloudStreamingProviders: [
                { label: 'Wowza', value: 0 },
                { label: 'Mux', value: 1 },
            ],
            streamingProviders: [
                'XSplit',
                'Android App (Larix)',
                'MemoryShare App (IOS)',
                'RTMP',
                'Teradek',
                'RTSP',
                // 'WebRTC',
            ],
            websiteProviders: [
                'FuneralOne',
                'Batesville',
                'FuneralNet',
                'Frazer',
                'Beyondfunerals',
                'FuneralFuturistWebsites',
                'AzureRawLink',
                'CFS',
                'WordPress',
                'GenericIframe',
                'Aurora',
                'IframeLink',
                'Button',
                'ViewingLink',
                'NonResponsive',
            ],
            wowzaServerLocations: [
                'asia_pacific_australia',
                'asia_pacific_india',
                'asia_pacific_japan',
                'asia_pacific_s_korea',
                'asia_pacific_singapore',
                'asia_pacific_taiwan',
                'eu_belgium',
                'eu_germany',
                'eu_ireland',
                'south_america_brazil',
                'us_central_iowa',
                'us_east_s_carolina',
                'us_east_virginia',
                'us_west_california',
                'us_west_oregon',
            ],
        };
    },
    computed: {
        filteredStorageTypes() {
            return this.storageTypes.map(item => ({ ...item, disabled: item.value == 3 }));
        },
    },
    methods: {
        openDialog() {
            this.dialog = true;
            this.getSettings();
            //     setTimeout(() => {
            //         this.initUppy();
            //     });
        },

        ...mapActions(['showSnackbar']),
        handleRefresh() {
            this.getSettings();
        },
        notGreaterThanMaxLimit(value) {
            return value <= this.maxTributeLimit || `Value must not be greater than ${this.maxTributeLimit}`;
        },
        getEmbedCodeType() {
            this.axios
                .create({
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .get(process.env.VUE_APP_API + `/dropdown/embedcode`)
                .then(response => {
                    if (response.data) {
                        this.embedCodeData = response.data;
                    }
                })
                .catch(error => {});
        },
        mapResponseToSettingsForm(data) {
            return {
                wowzaServerLocation: data.wowzaServerLocation,
                websiteProvider: this.websiteProviders[data.websiteProvider],
                streamingProvider: this.streamingProviders[data.streamingProvider],
                transcoded: data.transcoded,
                whiteLabel: data.whiteLabel,
                callToActionHeading: data.callToActionHeading,
                callToActionButtonText: data.callToActionButtonText,
                callToActionButtonLink: data.callToActionButtonLink,
                embedCodeId: data.embedCodeId,
                logoPath: data.logoPath,
                cloudStreamingProvider: data.cloudStreamingProvider,
                premiumSupport: !data.doNotWatch,
                shopLinkDisplayed: data.shopLinkDisplayed,
                tributeVideos: data.tributeVideos,
                tributeVideoHDRender: data.tributeVideoHDRender,
                maxTributeVideoItems: data.maxTributeVideoItems,
                hlsEnabled: data.hlsEnabled,
                storageType: data.storageType,
                marketRadiusMiles: data.marketRadiusMiles,
                betaFeatures: data.betaFeatures,
                guestBookConfigURL: data.guestBookConfigURL,
                // azureVMSize: data.azureVMSize,
                // displayTutorial: data.displayTutorial,
                // dvdForSale: data.dvdForSale,
                // displayCTA: data.displayCTA,
                // enablePreview: data.enablePreview,
            };
        },
        mapSettingsFormToPayload() {
            const isSuperAdmin = this.$auth.role.includes('SuperAdmin');

            const basePayload = {
                funeralHomeId: isSuperAdmin ? +this.id : this.$auth.funeralHomeId,
                logoPath: this.settingsForm.logoPath,
                streamingProvider: this.streamingProviders.indexOf(this.settingsForm.streamingProvider),
                callToActionHeading: this.settingsForm.callToActionHeading,
                callToActionButtonText: this.settingsForm.callToActionButtonText,
                callToActionButtonLink: this.settingsForm.callToActionButtonLink,
                embedCodeId: this.settingsForm.embedCodeId,
                maxTributeVideoItems: parseInt(this.settingsForm.maxTributeVideoItems),
                marketRadiusMiles: this.settingsForm.marketRadiusMiles,
            };

            if (isSuperAdmin) {
                return {
                    ...basePayload,
                    websiteProvider: this.websiteProviders.indexOf(this.settingsForm.websiteProvider),
                    transcoded: this.settingsForm.transcoded,
                    wowzaServerLocation: this.settingsForm.wowzaServerLocation,
                    cloudStreamingProvider: this.settingsForm.cloudStreamingProvider,
                    doNotWatch: !this.settingsForm.premiumSupport,
                    shopLinkDisplayed: this.settingsForm.shopLinkDisplayed,
                    tributeVideos: this.settingsForm.tributeVideos,
                    tributeVideoHDRender: this.settingsForm.tributeVideoHDRender,
                    hlsEnabled: this.settingsForm.hlsEnabled,
                    storageType: this.settingsForm.storageType,
                    betaFeatures: this.settingsForm.betaFeatures,
                    guestBookConfigURL: this.settingsForm.guestBookConfigURL,
                };
            }

            return basePayload;
        },
        getFuneralHomeId() {
            if (this.$auth.role.includes('SuperAdmin')) {
                return this.$route.params.id;
            } else if (this.$auth.role.includes('Owner') || this.$auth.role.includes('RegionalManager')) {
                return this.$route.params.id;
            } else {
                return this.$auth.funeralHomeId;
            }
        },
        async getSettings() {
            try {
                const fhId = this.getFuneralHomeId();
                const isSuperAdmin = this.$auth.role.includes('SuperAdmin');

                const apiMethod = isSuperAdmin ? 'getSettingsAdmin' : 'getSettings';

                const resp = await this.funeralHomeApi[apiMethod](fhId);

                if (resp.data) {
                    this.settingsForm = this.mapResponseToSettingsForm(resp.data);
                }
            } catch (error) {
                console.log('error');
                this.showSnackbar({ message: 'Error loading settings', color: 'error' });
            }
        },
        async save() {
            if (!this.formValid) return;

            try {
                this.busy = true;
                const isSuperAdmin = this.$auth.role.includes('SuperAdmin');

                const payload = this.mapSettingsFormToPayload();

                const apiMethod = isSuperAdmin ? 'updateSettingsAdmin' : 'updateSettings';

                const resp = await this.funeralHomeApi[apiMethod](payload);

                this.showSnackbar({ message: 'Settings updated' });
                this.$emit('message', 'Home updated');
            } catch (error) {
                console.log('error', error);
                this.showSnackbar({ message: "Error. Settings couldn't be updated." });
                this.$emit('message', 'Error updating home');
            } finally {
                this.busy = false;
            }
        },
    },
    mounted() {
        this.funeralHomeApi = FuneralHomeService(this.token);

        this.getEmbedCodeType();
        this.getSettings();
    },
};
</script>

<style lang="scss" scoped>
.clear-bottom {
    margin-bottom: -1rem;
}

.disabled-bg {
    padding: 0 1rem;
    border-radius: 5px;
    background: #f8f8f8;
}
</style>
